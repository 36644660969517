<template>
  <footer class="bg-blue-dark py-5">
    <div class="container">
      <div class="row">
        <div class="col-xxl-2 col-10 offset-1 justify-content-center d-flex">
          <a href="#"
            ><img class="icon-social" src="/images/icon-whatsapp.svg"
          /></a>
          <a href="#"
            ><img class="icon-social" src="/images/icon-instagram.svg"
          /></a>
        </div>
        <div class="col-xxl-6 col-10 offset-xxl-0 offset-1">
          <span class="text-white text-uppercase text-center d-block"
            >© horse march | todos os DIREITOS RESERVADOS | 2024</span
          >
        </div>
        <div
          class="col-xxl-1 col-4 offset-xxl-1 offset-4 d-flex justify-content-center"
        >
          <router-link :to="{ name: 'Home' }">
            <img
              src="/images/logo.webp"
              class="w-100"
              alt="Logomarca da Horse Math"
            />
          </router-link>
        </div>
      </div>
    </div>

    <div
      class="infos-apps"
      :title="`[env] ${appMode}\r\n[app] v${appVersion}\r\n[api] v${dbVersion}`"
    >
      {{ appMode }} | {{ appVersion }} | {{ dbVersion }}
    </div>
  </footer>
</template>
<script>
import packageInfo from "../../package.json"; // Ajuste o caminho conforme necessário

export default {
  name: "SectionFooter",
  data() {
    return {
      dbVer: "",
    };
  },
  computed: {
    appMode() {
      return process.env.VUE_APP_MODE;
    },
    appVersion() {
      return packageInfo.version;
    },
    dbVersion() {
      return this.dbVer;
    },
  },
  created() {
    this.fetchDbVersion();
  },

  methods: {
    async fetchDbVersion() {
      try {
        const response = await this.$sdk.get("/version");
        this.dbVer = response.data.version || "erro";
      } catch (error) {
        console.error("Erro ao buscar a versão do DB:", error);
        this.dbVer = "Erro ao buscar";
      }
    },
  },
};
</script>
<style lang="scss">
footer {
  position: relative;

  span {
    font-size: 13px;
    margin: 7px 0;
  }

  .icon-social {
    width: 30px;
    margin-right: 10px;
  }

  .infos-apps {
    color: #ffffff;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 9px;
    opacity: 0.2;
    padding: 5px 10px;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
