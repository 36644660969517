<template>
  <div class="pg-busca">
    <section-header />
    <div class="container">
      <div class="row">
        <div class="col pt-5">
          <div class="content content-col-1">
            <h1 class="text-yellow text-uppercase">Busca de Animais</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-7">
          <p>Selecione as características desejadas nos filtros, busque por nome ou navegue em nosso catálogo completo.</p>
        </div>
        <div class="col-xl-4 offset-xl-1">
          <div class="input-group mb-3 campo-busca">
            <input
              type="text"
              class="form-control rounded-pill"
              placeholder="Pesquisar..."
              aria-label="Pesquisar..."
              aria-describedby="button-addon2"
              v-model="filtros.q"
              :disabled="loading"
              @keyup.enter="loadData"
            />
            <button id="button-addon2" class="btn btn-outline-secondary position-absolute rounded-circle bg-blue-dark" type="button" :disabled="loading" @click="loadData">
              <img src="/images/icon-search.svg" />
            </button>
          </div>
        </div>
      </div>

      <div class="row py-3">
        <div class="col">
          <div class="row">
            <div class="col-xl-2 py-2">
              <span class="text-uppercase text-white fw-bold">Filtros Gerais:</span>
            </div>
            <div class="col py-2">
              <div class="row">
                <div v-for="(filtro, filtroID) in opcoes.filtros" :key="filtro.ID" class="col-auto">
                  <div class="form-check">
                    <input
                      :id="`check_filtros_${filtro.Tipo}_${filtro.ID}`"
                      class="form-check-input"
                      type="checkbox"
                      :disabled="loading"
                      :title="filtro.Tipo == 'raca' ? 'Raça' : 'Característica'"
                      v-model="filtrosTemp"
                      :value="filtro"
                    />
                    <label class="form-check-label text-white" :for="`check_filtros_${filtro.Tipo}_${filtro.ID}`" :title="filtro.Tipo == 'raca' ? 'Raça' : 'Característica'">
                      {{ filtro.Nome }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col border border-white border-2 border-top-0 border-end-0 border-bottom-0">
          <div class="row">
            <div class="col-xl-2 py-2">
              <span class="text-uppercase text-white fw-bold">Pelagem:</span>
            </div>
            <div class="col py-2">
              <div class="row">
                <div v-for="(pelagem, pelagemID) in opcoes.pelagens" :key="pelagem.ID" class="col-auto">
                  <div class="form-check">
                    <input :id="`check_pelagens_${pelagem.ID}`" class="form-check-input" type="checkbox" :disabled="loading" v-model="filtros.pelagens" :value="pelagem.ID" />
                    <label class="form-check-label text-white" :for="`check_pelagens_${pelagem.ID}`">
                      {{ pelagem.Nome }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white">
      <div class="container">
        <div class="row">
          <div class="col py-5">
            <div class="alert alert-warning" role="alert" v-if="animais.length == 0 && !loading">
              <strong>Nenhum animal encontrado.</strong> Experimente ajustar os filtros para encontrar novos resultados.
            </div>
            <grid v-if="!loading" :data="animais" />
            <loader v-if="loading" style="padding: 200px 0" />
          </div>
        </div>
      </div>
    </div>
    <section-footer />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      animais: [],
      page: 1,
      orderRandID: null,
      filtros: {
        q: null,
        pelagens: [],
        racas: [],
        caracteristicas: [],
      },
      filtrosTemp: [],
      opcoes: {
        estados: [
          'AC', // Acre
          'AL', // Alagoas
          'AP', // Amapá
          'AM', // Amazonass
          'BA', // Bahia
          'CE', // Ceará
          'DF', // Distrito Federal
          'ES', // Espírito Santo
          'GO', // Goiás
          'MA', // Maranhão
          'MT', // Mato Grosso
          'MS', // Mato Grosso do Sul
          'MG', // Minas Gerais
          'PA', // Pará
          'PB', // Paraíba
          'PR', // Paraná
          'PE', // Pernambuco
          'PI', // Piauí
          'RJ', // Rio de Janeiro
          'RN', // Rio Grande do Norte
          'RS', // Rio Grande do Sul
          'RO', // Rondônia
          'RR', // Roraima
          'SC', // Santa Catarina
          'SP', // São Paulo
          'SE', // Sergipe
          'TO', // Tocantins
        ],
        filtros: [],
        pelagens: [],
      },
    }
  },

  watch: {
    filtrosTemp: {
      handler(newValue) {
        this.filtros.racas = newValue.filter((item) => item.Tipo === 'raca').map((item) => item.ID)
        this.filtros.caracteristicas = newValue.filter((item) => item.Tipo === 'caracteristica').map((item) => item.ID)
      },
      deep: true,
    },
    'filtros.racas': {
      handler(newValue, oldValue) {
        if (!this.arraysEqual(newValue, oldValue)) {
          this.loadData()
        }
      },
      deep: true,
    },
    'filtros.caracteristicas': {
      handler(newValue, oldValue) {
        if (!this.arraysEqual(newValue, oldValue)) {
          this.loadData()
        }
      },
      deep: true,
    },
    'filtros.pelagens': {
      handler(newValue, oldValue) {
        if (!this.arraysEqual(newValue, oldValue)) {
          this.loadData()
        }
      },
      deep: true,
    },
  },

  async mounted() {
    this.$useSEO({
      title: 'Pesquisar',
      description: 'XXX',
    }).setMetaTags()

    // carrega opções de filtro
    let opcoes = await this.$sdk.get('/app/opcoes')
    this.preparaOpcoes(opcoes)

    await this.loadData()
  },

  methods: {
    arraysEqual(a, b) {
      if (a === b) return true
      if (a == null || b == null) return false
      if (a.length !== b.length) return false

      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false
      }
      return true
    },
    preparaOpcoes(opcoes) {
      // pelagens
      if (opcoes.data.pelagens) {
        this.opcoes.pelagens = opcoes.data.pelagens
      }

      // racas e caracteristicas
      if (opcoes.data.racas && opcoes.data.caracteristicas) {
        const racas = opcoes.data.racas.map((raca) => ({
          ID: raca.ID,
          Nome: raca.Nome,
          Tipo: 'raca',
        }))

        const caracteristicas = opcoes.data.caracteristicas.map((caracteristica) => ({
          ID: caracteristica.ID,
          Nome: caracteristica.Nome,
          Tipo: 'caracteristica',
        }))

        this.opcoes.filtros = [...racas, ...caracteristicas]
      }
    },
    async loadData(restart = true) {
      this.loading = true

      //se é nova página, informa
      if (!restart) {
        this.page++
      }

      let params = {
        q: this.filtros.q ? this.filtros.q : '',
        'filter.collages': this.filtros.pelagens,
        'filter.breeds': this.filtros.racas,
        'filter.particulars': this.filtros.caracteristicas,
      }

      if (this.orderRandID) {
        params['orderRandID'] = this.orderRandID
      }

      if (this.page) {
        params['page'] = this.page
      }

      let data = await this.$sdk.get('/app/animais', params)

      if (restart) {
        this.animais = data.data
      } else {
        this.animais = [...this.animais, ...data.data]
      }

      // atualiza parâmetros do componente
      if (data.orderRandID) {
        this.orderRandID = data.orderRandID
      }

      if (data.page) {
        this.page = data.page
      }
      this.loading = false
    },
    submitForm() {},
  },
}
</script>

<style lang="scss">
.pg-busca {
  .campo-busca {
    button {
      right: 1px;
      height: 36px;
      width: 36px;
      border: 0;
      top: 1px;
      z-index: 6;
    }
  }
}
</style>
