import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Swal from 'sweetalert2'
import sdk from './sdk'
import useSEOPlugin from './plugins/useSEO'

// Bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min'

// Plugins
// import initializeChoices from './plugins/choices-setup'; // Importando a configuração do Choices.js
// initializeChoices();

// Estilos
import './assets/styles/load.scss'

// Componentes Globais
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Grid from './components/Grid.vue'
import GridItem from './components/GridItem.vue'
import Loader from './components/Loader.vue'
import CavaloItem from './components/cavaloItem.vue'

const app = createApp(App)

console.log('[ENV]', process.env.VUE_APP_MODE)

// adiciona SDK
app.config.globalProperties.$sdk = sdk

// adiciona Swal
app.config.globalProperties.$swal = Swal

// vue-the-mask
import VueTheMask from 'vue-the-mask'
app.use(VueTheMask)

app.component('SectionHeader', Header)
app.component('SectionFooter', Footer)
app.component('Grid', Grid)
app.component('GridItem', GridItem)
app.component('Loader', Loader)
app.component('CavaloItem', CavaloItem)

app.use(useSEOPlugin)
app.use(router)
app.mount('#app')
