<template>
  <div class="pg-animal">
    <section-header />
    <loader v-if="loading" />
    <div v-if="!loading" class="bg-stripe-blue pb-5">
      <div class="container position-relative">
        <div class="row">
          <div class="col-xl-5">
            <div>
              <div class="thumb square rounded-1 rounded-top-0 bg-image-cover" :style="{ 'background-image': `url(${featured.thumb || featured})` }" />
            </div>
            <div v-if="galeria && galeria.length >= 2">
              <h2 class="text-primary mt-4 mb-2 text-uppercase">Galeria:</h2>

              <carousel :items-to-show="2" :items-to-scroll="2">
                <slide v-for="(foto, fotoID) in galeria" :key="fotoID">
                  <div class="w-100 p-2">
                    <div class="square rounded-1 bg-image-cover" :style="{ 'background-image': `url(${foto.thumb})` }" />
                  </div>
                </slide>

                <template #addons>
                  <navigation />
                </template>
              </carousel>
            </div>
          </div>
          <div class="col-xl-5">
            <div v-if="!loading">
              <h1 class="fs-1 mt-5 pt-3 text-uppercase">{{ animal.animalNome }}</h1>
              <span class="d-block text-white text-uppercase fs-5 pb-5">{{ animal.animalGenero == 'M' ? 'Macho' : 'Fêmea' }} - {{ animal.animalRacaNome }} - {{ animal.animalMarchaNome }}</span>

              <table class="table table-bordered border-primary mt-5">
                <tr>
                  <td>Criador:</td>
                  <td>{{ animal.animalNomeCriador }}</td>
                </tr>
                <tr>
                  <td>Criatório</td>
                  <td>{{ animal.animalCriatorio }}</td>
                </tr>
                <tr>
                  <td>Proprietário:</td>
                  <td>{{ animal.animalNomeCriador }}</td>
                </tr>
                <tr>
                  <td>Haras</td>
                  <td>{{ animal.proprietarioHaras }}</td>
                </tr>
                <tr>
                  <td>Data de Nascimento:</td>
                  <td>{{ animal.animalDataNascimento }}</td>
                </tr>
                <tr>
                  <td>Registro</td>
                  <td>{{ animal.animalRegistro }}</td>
                </tr>
                <tr>
                  <td>Chip</td>
                  <td>{{ animal.animalChip }}</td>
                </tr>
              </table>

              <div class="d-grid gap-2" v-if="false">
                <button class="btn btn-primary" type="button">Filhos</button>
                <button class="btn btn-primary" type="button">Premiações</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loading && false" class="bg-primary py-3">
      <div class="container">
        <div class="row">
          <div class="col">
            <h2 class="text-uppercase text-center m-0 text-uppercase">Genealogia:</h2>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loading && false" class="bg-white py-4">
      <div class="container">
        <div class="row">
          <div class="col genealogia genealogia-nivel1">
            <div class="item">
              <div class="casal">
                <span
                  >ESTEIO DE TRÊS CORAÇÕES<br />
                  Reg.: 045717 | DNA - VP | MM5 | MM7</span
                >
              </div>
            </div>
            <div class="item">
              <div class="casal">
                <span
                  >SANFONA DA ILHA<br />
                  Reg.: 0152413 | DNA - VP | MM6 |
                </span>
              </div>
            </div>
          </div>
          <div class="col genealogia genealogia-nivel2">
            <div class="item">
              <div class="casal">
                <span
                  >ESTANHO DE ALCATÉIA<br />
                  Reg.: 024196 | DNA - VP | MM5 | MM8</span
                >

                <span
                  >ASABRANCA DE TRÊS CORAÇÕES<br />
                  Reg.: 0121759 | DNA - VP | MM6 | MM7</span
                >
              </div>
            </div>
            <div class="item">
              <div class="casal">
                <span
                  >ESTANHO DE ALCATÉIA<br />
                  Reg.: 024196 | DNA - VP | MM5 | MM8</span
                >
                <span>
                  ASABRANCA DE TRÊS CORAÇÕES<br />
                  Reg.: 0121759 | DNA - VP | MM6 | MM7</span
                >
              </div>
            </div>
          </div>
          <div class="col genealogia genealogia-nivel3">
            <div class="item">
              <div class="casal">
                <span
                  >ESTANHO DE ALCATÉIA<br />
                  Reg.: 024196 | DNA - VP | MM5 | MM8</span
                >

                <span
                  >ASABRANCA DE TRÊS CORAÇÕES<br />
                  Reg.: 0121759 | DNA - VP | MM6 | MM7</span
                >
              </div>
            </div>
            <div class="item">
              <div class="casal">
                <span
                  >ESTANHO DE ALCATÉIA<br />
                  Reg.: 024196 | DNA - VP | MM5 | MM8</span
                >
                <span>
                  ASABRANCA DE TRÊS CORAÇÕES<br />
                  Reg.: 0121759 | DNA - VP | MM6 | MM7</span
                >
              </div>
            </div>
            <div class="item">
              <div class="casal">
                <span
                  >ESTANHO DE ALCATÉIA<br />
                  Reg.: 024196 | DNA - VP | MM5 | MM8</span
                >

                <span
                  >ASABRANCA DE TRÊS CORAÇÕES<br />
                  Reg.: 0121759 | DNA - VP | MM6 | MM7</span
                >
              </div>
            </div>
            <div class="item">
              <div class="casal">
                <span
                  >ESTANHO DE ALCATÉIA<br />
                  Reg.: 024196 | DNA - VP | MM5 | MM8</span
                >
                <span>
                  ASABRANCA DE TRÊS CORAÇÕES<br />
                  Reg.: 0121759 | DNA - VP | MM6 | MM7</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary py-1" v-if="!loading">
      <div class="container">
        <div class="row">
          <div class="col-4 position-relative">
            <img src="/images/logo-circulo.webp" class="position-absolute end-0" :style="{ top: '-24px' }" />
          </div>
          <div class="col">
            <router-link :to="{ name: 'Match', params: { id: this.$route.id, slug: this.$route.slug } }" class="text-uppercase fs-2 fw-bold d-block text-decoration-none">
              Quero combinar com um garanhão >
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white" v-if="!loading">
      <div class="container">
        <div class="row py-5">
          <div class="col-3 offset-3 d-flex justify-content-center">
            <img src="/images/mangalarga-logo.webp" />
          </div>

          <div class="col-3 d-flex justify-content-center">
            <img src="/images/associados.webp" />
          </div>
        </div>
      </div>
    </div>
    <section-footer />
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  components: {
    Carousel,
    Slide,

    Navigation,
  },
  data() {
    return {
      animal: null,
      fotos: null,
    }
  },
  computed: {
    loading() {
      return !this.animal
    },
    featured() {
      if (this.fotos?.length > 0) {
        return this.fotos[0].full
      } else {
        return '/images/semfoto.png'
      }
    },

    galeria() {
      if (this.fotos?.length > 1) {
        return this.fotos.slice(1)
      }

      return null
    },
  },

  async mounted() {
    let animalID = this.$route.params.id
    let animal = await this.$sdk.get(`/app/animais/${animalID}`)

    console.log('[ANIMAL]', animal)

    this.animal = animal.data

    if (animal.data.animalFotos?.length > 0) {
      this.fotos = animal.data.animalFotos.map((foto) => ({
        thumb: foto.url || foto,
        full: foto.url || foto,
      }))
    }

    this.$useSEO({
      title: `Detalhes do ${animal.data.animalNome}`,
      description: `Detalhes do ${animal.data.animalNome}`,
    }).setMetaTags()
  },
}
</script>

<style lang="scss">
.genealogia {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .item {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    margin: 15px 0;
  }

  &.genealogia-nivel2,
  &.genealogia-nivel3 {
    .casal {
      border-left: 2px solid var(--bs-primary);
      position: relative;
      &::before {
        content: '';
        width: 50px;
        height: 2px;
        border-top: 2px solid var(--bs-primary);
        position: absolute;
        top: 50%;
        left: -50px;
      }
    }
  }

  &.genealogia-nivel1 {
    .casal {
      height: 50%;
      justify-content: center;
    }
  }

  &.genealogia-nivel2 {
    .casal {
      height: 75%;
    }
  }

  .casal {
    height: 100%;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      display: block;
      padding: 5px 0;
    }
  }
}
</style>
