class SDK {
  constructor () {
    this.baseURL = process.env.VUE_APP_BASE_URL

    console.log('[SDK]', 'base URL', this.baseURL)
  }

  async request (method, endpoint, data = null, params = {}) {
    const url = new URL(`${this.baseURL}${endpoint}`)

    // Adicionar parâmetros de query string ao URL, se existirem
    if (params && method === 'GET') {
      Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]))
    }

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json'
      }
    }

    // Adicionar corpo à requisição para métodos POST, PUT, DELETE
    if (data && method !== 'GET') {
      options.body = JSON.stringify(data)
    }

    const response = await fetch(url, options)
    if (!response.ok) {
      throw new Error(`API call failed: ${response.statusText}`)
    }
    return response.json()
  }

  get (endpoint, params) {
    return this.request('GET', endpoint, null, params)
  }

  post (endpoint, data) {
    return this.request('POST', endpoint, data)
  }

  put (endpoint, data) {
    return this.request('PUT', endpoint, data)
  }

  delete (endpoint) {
    return this.request('DELETE', endpoint)
  }
}

export default new SDK()
