<template>
  <div class="pg-cadastro-animal">
    <section-header />
    <div class="container">
      <div class="row">
        <div class="col-xl-5 col-10 offset-1">
          <div class="content content-col-1">
            <h1 class="text-uppercase">Cadastro de Animais</h1>
            <form @submit.prevent="submitForm">
              <h2 class="py-3 text-uppercase">Dados do Animal:</h2>

              <!-- Campo Gênero -->
              <div class="row mb-3">
                <div class="col-xxl-2 col-6">
                  <div class="mb-3 form-check">
                    <input id="campoGeneroMacho" v-model="campos.animalGenero" type="radio" class="form-check-input" value="M" required :disabled="loading" />
                    <label class="form-check-label" for="campoGeneroMacho">Macho</label>
                  </div>
                </div>
                <div class="col-xxl-2 col-6">
                  <div class="mb-3 form-check">
                    <input id="campoGeneroFemea" v-model="campos.animalGenero" type="radio" class="form-check-input" value="F" required :disabled="loading" />
                    <label class="form-check-label" for="campoGeneroFemea">Fêmea</label>
                  </div>
                </div>
              </div>

              <!-- Campo Raça / Observações -->
              <div class="row mb-3">
                <div class="col-xxl-6">
                  <label for="campoRaca" class="form-label">Raça:</label>
                  <select id="campoRaca" v-model="campos.animalRacaID" class="form-select" required :disabled="loading">
                    <option selected />
                    <option v-for="(raca, racaID) in opcoes.racas" :key="racaID" :value="raca.ID">
                      {{ raca.Nome }}
                    </option>
                  </select>
                </div>
                <div class="col-xxl-6">
                  <label for="campoMarcha" class="form-label">Marcha:</label>
                  <select id="campoMarcha" v-model="campos.animalMarchaID" class="form-select" required :disabled="loading">
                    <option selected />
                    <option v-for="(marcha, marchaID) in opcoes.marchas" :key="marchaID" :value="marcha.ID">
                      {{ marcha.Nome }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Campo Nome-->
              <div class="row mb-3">
                <div class="col">
                  <label for="animalNome" class="form-label">Nome:</label>
                  <input id="animalNome" v-model="campos.animalNome" type="text" class="form-control" required :disabled="loading" />
                </div>
              </div>

              <!-- Campo Data de Nascimento-->
              <div class="row mb-3">
                <div class="col-xxl-7">
                  <label for="animalDataNascimento" class="form-label">Data de nascimento: (DD/MM/AAAA)</label>
                  <input id="animalDataNascimento" v-model="campos.animalDataNascimento" type="date" class="form-control" required :disabled="loading" />
                </div>
              </div>

              <!-- Campo Criatório-->
              <div class="row mb-3">
                <div class="col">
                  <label for="animalCriatorio" class="form-label">Criatório:</label>
                  <input id="animalCriatorio" v-model="campos.animalCriatorio" type="text" class="form-control" required :disabled="loading" />
                </div>
              </div>

              <!-- Campo Nome do Criador-->
              <div class="row mb-3">
                <div class="col">
                  <label for="animalNomeCriador" class="form-label">Nome do Criador:</label>
                  <input id="animalNomeCriador" v-model="campos.animalNomeCriador" type="text" class="form-control" required :disabled="loading" />
                </div>
              </div>

              <!-- Campo Registro / Chip-->
              <div class="row mb-3">
                <div class="col-xxl-6">
                  <label for="animalRegistro" class="form-label">Registro:</label>
                  <input id="animalRegistro" v-model="campos.animalRegistro" type="text" class="form-control" required :disabled="loading" />
                </div>
                <div class="col-xxl-6">
                  <label for="animalChip" class="form-label">Chip:</label>
                  <input id="animalChip" v-model="campos.animalChip" type="text" class="form-control" required :disabled="loading" />
                </div>
              </div>

              <!-- Campo Pelagem -->
              <div class="row mb-3">
                <div class="col-xxl-6">
                  <label for="campoPelagem" class="form-label">Pelagem:</label>
                  <select id="campoPelagem" v-model="campos.animalPelagemID" class="form-select" required :disabled="loading">
                    <option selected />
                    <option v-for="(pelagem, pelagemID) in opcoes.pelagens" :key="pelagemID" :value="pelagem.ID">
                      {{ pelagem.Nome }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Campo Observações-->
              <div class="row mb-3">
                <div class="col">
                  <label for="animalObservacoes" class="form-label">Observações:</label>
                  <input id="animalObservacoes" v-model="campos.animalObservacoes" type="text" class="form-control" :disabled="loading" />
                </div>
              </div>

              <!-- Campo Fotos-->
              <div class="row my-5">
                <div class="col">
                  <label for="animalFotos" class="form-label">Fotos:</label>
                  <input id="animalFotos" ref="fotos" class="form-control" type="file" multiple required :disabled="loading" />
                </div>
              </div>

              <!-- Campo Documentos-->
              <div class="row my-5">
                <div class="col">
                  <label for="animalDocumentos" class="form-label">Documentos:</label>
                  <input id="animalDocumentos" ref="documentos" class="form-control" type="file" multiple required :disabled="loading" />
                </div>
              </div>

              <!-- SEPARADOR -->
              <div class="row">
                <div class="col">
                  <hr />
                </div>
              </div>

              <h2 class="py-3 text-uppercase">Dados do Proprietário</h2>

              <!-- Campo Nome Proprietário -->
              <div class="row mb-3">
                <div class="col-xxl-12">
                  <label for="proprietarioNome" class="form-label">Nome completo:</label>
                  <input id="proprietarioNome" v-model="campos.proprietarioNome" type="text" class="form-control" required :disabled="loading" />
                </div>
              </div>

              <!-- Campo Nome do Haras -->
              <div class="row mb-3">
                <div class="col-xxl-12">
                  <label for="proprietarioHaras" class="form-label">Nome do haras:</label>
                  <input id="proprietarioHaras" v-model="campos.proprietarioHaras" type="text" class="form-control" required :disabled="loading" />
                </div>
              </div>

              <!-- Campo Endereço -->
              <div class="row mb-3">
                <div class="col-xxl-12">
                  <label for="proprietarioEndereco" class="form-label">Endereço completo:</label>
                  <input id="proprietarioEndereco" v-model="campos.proprietarioEndereco" type="text" class="form-control" required :disabled="loading" />
                </div>
              </div>

              <!-- Campo Endereço -->
              <div class="row mb-3">
                <div class="col-xxl-5">
                  <label for="proprietarioEnderecoCidade" class="form-label">Cidade:</label>
                  <input id="proprietarioEnderecoCidade" v-model="campos.proprietarioEnderecoCidade" type="text" class="form-control" required :disabled="loading" />
                </div>
                <div class="col-xxl-3">
                  <label for="proprietarioEnderecoEstado" class="form-label">Estado:</label>
                  <select id="proprietarioEnderecoEstado" v-model="campos.proprietarioEnderecoEstado" class="form-select" required :disabled="loading">
                    <option selected />
                    <option v-for="(estado, estadoID) in opcoes.estados" :key="estadoID" :value="estado">
                      {{ estado }}
                    </option>
                  </select>
                </div>
                <div class="col-xxl-4">
                  <label for="proprietarioEnderecoCEP" class="form-label">CEP:</label>
                  <input id="proprietarioEnderecoCEP" v-model="campos.proprietarioEnderecoCEP" type="text" class="form-control" required :disabled="loading" v-mask="'##.###-###'" />
                </div>
              </div>

              <!-- Campo Telefone -->
              <div class="row mb-3">
                <div class="col-xxl-7">
                  <label for="proprietarioTelefone" class="form-label">Telefone de Contato:</label>
                  <input id="proprietarioTelefone" v-model="campos.proprietarioTelefone" type="text" class="form-control" required :disabled="loading" />
                </div>
              </div>

              <!-- Campo E-mail -->
              <div class="row mb-3">
                <div class="col">
                  <label for="proprietarioEmail" class="form-label">E-mail:</label>
                  <input id="proprietarioEmail" v-model="campos.proprietarioEmail" type="email" class="form-control" required :disabled="loading" />
                </div>
              </div>

              <!-- SEPARADOR -->
              <div class="row">
                <div class="col">
                  <hr />
                </div>
              </div>

              <!-- BOTÃO -->
              <div class="row mb-5">
                <div class="col">
                  <button class="btn btn-white px-5" type="submit" :disabled="loading">
                    {{ !loading ? 'Enviar formulário' : 'Aguarde...' }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-xl-4 col-10 offset-xl-1 offset-1">
          <div class="content content-col-2 pb-5">
            <p>Cadastre seu animal para fazer parte da seleção do Horse Match.</p>
            <p>
              <strong>
                IMPORTANTE:<br />
                Envie-nos o documento do seu animal e 4 fotos com boa iluminação e qualidade. Sugestão dos ângulos abaixo:</strong
              >
              <br /><br />
              <img src="/images/angulos.png" />
            </p>

            <p>
              Após o cadastro, você deverá entrar em contato com o técnico da raça mais próximo. Uma avaliação das características do seu animal, como medidas e altura será feita. Concluindo este
              processo seu garanhão ou égua estará apto (a) a fazer parte do time Horse Match!
            </p>

            <h2 class="my-5 text-uppercase">Faça parte da nossa comunidade e vamos juntos contribuir para o rápido melhoramento genético dos criatórios e seleção de animais mais completos!</h2>

            <img src="/images/simbolo.webp" />
          </div>
        </div>
      </div>
    </div>
    <section-footer />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      opcoes: {
        racas: [],
        marchas: [],
        pelagens: [],
        caracteristicas: [],
        estados: [
          'AC', // Acre
          'AL', // Alagoas
          'AP', // Amapá
          'AM', // Amazonas
          'BA', // Bahia
          'CE', // Ceará
          'DF', // Distrito Federal
          'ES', // Espírito Santo
          'GO', // Goiás
          'MA', // Maranhão
          'MT', // Mato Grosso
          'MS', // Mato Grosso do Sul
          'MG', // Minas Gerais
          'PA', // Pará
          'PB', // Paraíba
          'PR', // Paraná
          'PE', // Pernambuco
          'PI', // Piauí
          'RJ', // Rio de Janeiro
          'RN', // Rio Grande do Norte
          'RS', // Rio Grande do Sul
          'RO', // Rondônia
          'RR', // Roraima
          'SC', // Santa Catarina
          'SP', // São Paulo
          'SE', // Sergipe
          'TO', // Tocantins
        ],
      },
      campos: {
        animalCaracteristicas: [],
        animalChip: null,
        animalCriatorio: null,
        animalDataNascimento: null,
        animalGenero: null,
        animalNome: null,
        animalNomeCriador: null,
        animalObservacoes: null,
        animalRegistro: null,
        proprietarioEmail: null,
        proprietarioEndereco: null,
        proprietarioEnderecoCEP: null,
        proprietarioEnderecoCidade: null,
        proprietarioEnderecoEstado: null,
        proprietarioHaras: null,
        proprietarioNome: null,
        proprietarioTelefone: null,
        animalDocumentos: null,
        animalFotos: null,
      },
    }
  },

  async mounted() {
    this.$useSEO({
      title: 'Cadastrar Animal',
      description: 'Cadastre seus animais para que façam parte do catálogo do Horse Match, ampliando ainda mais as opções para alcançar o melhor resultado possível.',
    }).setMetaTags()

    //carrega opções na API
    await this.loadOptions()
  },

  methods: {
    async loadOptions() {
      let opcoes = await this.$sdk.get('/app/opcoes')

      this.opcoes.racas = opcoes.data.racas || []
      this.opcoes.pelagens = opcoes.data.pelagens || []
      this.opcoes.marchas = opcoes.data.marchas || []
      this.opcoes.caracteristicas = opcoes.data.caracteristicas || []
    },
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },

    async validateFilesDocumentos() {
      const files = this.$refs.documentos.files
      const maxAllowedSize = 2 * 1024 * 1024 // 2 MB em bytes
      for (const file of files) {
        if (file.size > maxAllowedSize) {
          await this.$swal.fire({
            title: 'Documentos inválidos',
            text: 'Um ou mais arquivos excedem o limite máximo de 2 MB.',
            icon: 'warning',
            confirmButtonText: 'Ok!',
          })

          this.$refs.documentos.value = '' // Limpa o campo de arquivo
          return false // Indica falha na validação
        }
      }
      return true // Indica sucesso na validação
    },

    async validateFilesFotos() {
      const files = this.$refs.fotos.files
      const maxAllowedSize = 2 * 1024 * 1024 // 2 MB em bytes
      for (const file of files) {
        if (file.size > maxAllowedSize) {
          await this.$swal.fire({
            title: 'Fotos inválidas',
            text: 'Um ou mais arquivos excedem o limite máximo de 2 MB.',
            icon: 'warning',
            confirmButtonText: 'Ok!',
          })

          this.$refs.fotos.value = '' // Limpa o campo de arquivo
          return false // Indica falha na validação
        }
      }
      return true // Indica sucesso na validação
    },

    async processFilesDocumentos() {
      console.log('Validando documentos...')
      const isValid = await this.validateFilesDocumentos()
      if (!isValid) {
        // Se a validação falhar, saia do método
        console.log('Validação falhou.')
        return false
      }
      console.log('Documentos validados!')

      const files = this.$refs.documentos.files // Acessa os arquivos após a validação
      try {
        const promises = Array.from(files).map((file) => this.fileToBase64(file))
        const base64Files = await Promise.all(promises)
        this.campos.animalDocumentos = base64Files // Armazena os documentos convertidos em Base64

        return true
      } catch (error) {
        console.error('[documentos] Erro ao converter arquivos:', error)
      }
    },

    async processFilesFotos() {
      console.log('Validando Fotos...')
      const isValid = await this.validateFilesFotos()
      if (!isValid) {
        // Se a validação falhar, saia do método
        console.log('Validação falhou.')
        return false
      }
      console.log('Fotos validados!')

      const files = this.$refs.fotos.files // Acessa os arquivos após a validação
      try {
        const promises = Array.from(files).map((file) => this.fileToBase64(file))
        const base64Files = await Promise.all(promises)
        this.campos.animalFotos = base64Files // Armazena os documentos convertidos em Base64

        return true
      } catch (error) {
        console.error('[fotos] Erro ao converter arquivos:', error)
      }
    },

    async submitForm() {
      if (!this.campos.animalGenero) {
        this.$swal.fire({
          title: 'Selecione o Gênero',
          text: 'Antes de enviar os seus dados, precisa selecionar um Gênero.',
          icon: 'warning',
          confirmButtonText: 'Ok!',
        })

        return
      }

      this.loading = true

      /** DOCUMENTOS */
      const isValidDocumentos = await this.processFilesDocumentos()

      if (!isValidDocumentos) {
        this.loading = false
        return
      }

      console.log('Documentos processados:', this.campos.animalDocumentos)

      /** FOTOS */
      const isValidFotos = await this.processFilesFotos()

      if (!isValidFotos) {
        this.loading = false
        return
      }

      console.log('Documentos processados:', this.campos.animalDocumentos)

      try {
        const ret = await this.$sdk.post('/app/animais', this.campos)

        console.log('[ANIMAIS]', 'Cadastrando', ret)

        if (ret.status == 201) {
          await this.$swal.fire({
            title: 'Animal cadastrado com sucesso!',
            text: 'Os dados foram enviados com sucesso. Em breve entraremos em contato com você.',
            icon: 'success',
            confirmButtonText: 'Ok!',
          })

          this.$router.push({ name: 'Home' })
        } else {
          await this.$swal.fire({
            title: 'Não foi possível cadastrar o animal agora.',
            text: 'Infelizmente não foi possível cadastrar agora o animal. Tente novamente mais tarde.',
            icon: 'error',
            confirmButtonText: 'Ok!',
          })
        }
      } catch (error) {
        console.error('Failed to fetch users:', error.message)

        this.$swal.fire({
          title: 'Houve um erro ao cadastraro seu animal',
          text: 'Infelizmente tivemos um problema técnico ao enviar os seus dados. Tente novamente em breve.',
          icon: 'error',
          confirmButtonText: 'Ok!',
        })
      }

      this.loading = false
    },
  },
}
</script>

<style lang="scss">
.pg-cadastro-animal {
  .content-col-1 {
    padding-top: 45px;
  }

  .content-col-2 {
    padding-top: 90px;
  }
}
</style>
