<template>
  <div class="grid-cavalos-item w-100 mb-5">
    <RouterLink :to="{ name: 'Animal', params: { id: data.ID, slug: linkAmigavel } }">
      <div class="thumb square rounded-1 bg-image-cover" :style="{ 'background-image': `url(${primeiraFoto})` }" />
      <h3 class="text-primary text-uppercase fw-bold py-2 text-truncate" title="Nome do animal completo">{{ data.animalNome }}</h3>
      <span class="d-block fw-bold text-truncate text-black">{{ data.animalGenero == 'M' ? 'Macho' : 'Fêmea' }}, {{ data.animalRacaNome }}, {{ data.animalPelagemNome }}</span>
      <span class="d-block text-truncate text-black">{{ caracteristicasNome }}</span>
    </RouterLink>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    caracteristicasNome() {
      const nomes = this.data.animalCaracteristicasNome
      if (nomes.length === 0) return ''
      if (nomes.length === 1) return nomes[0]
      return nomes.slice(0, -1).join(', ') + ' e ' + nomes[nomes.length - 1]
    },

    primeiraFoto() {
      if (!this.data.animalFotos || !Array.isArray(this.data.animalFotos) || this.data.animalFotos.length === 0) {
        return '/images/semfoto.png'
      }

      return this.data.animalFotos[0].url || this.data.animalFotos[0]
    },
    linkAmigavel() {
      return this.data.animalNome
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Substitui espaços por -
        .replace(/[^\w\-]+/g, '') // Remove todos os caracteres não-alfanuméricos
        .replace(/\-\-+/g, '-') // Substitui múltiplos - por um único -
        .replace(/^-+/, '') // Remove - do início
        .replace(/-+$/, '') // Remove - do final
    },
  },
}
</script>
<style lang="scss">
.grid-cavalos-item {
  .thumb {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &:hover {
    h3 {
      text-decoration: underline;
    }
  }
}
</style>
