<template>
  <div class="cavalo-item">
    <RouterLink :to="{ name: 'Animal', params: { id: data.ID, slug: linkAmigavel } }">
      <div class="thumb square rounded-1 rounded-top-0 bg-image-cover" :style="{ 'background-image': `url(${primeiraFoto})` }" />
      <h3 class="text-primary fw-bold text-uppercase py-1">{{ data.animalNome }}</h3>
      <span class="d-block fw-medium">{{ data.animalRacaNome }}</span>
      <span class="d-block">{{ data.animalMarchaNome }}</span>
      <span class="d-block">{{ data.animalGenero == 'M' ? 'Macho' : 'Fêmea' }}</span>
    </RouterLink>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: null,
    },
  },

  computed: {
    primeiraFoto() {
      if (!this.data.animalFotos || !Array.isArray(this.data.animalFotos) || this.data.animalFotos.length === 0) {
        return '/images/semfoto.png'
      }

      return this.data.animalFotos[0].url || this.data.animalFotos[0]
    },
    linkAmigavel() {
      return this.data.animalNome
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Substitui espaços por -
        .replace(/[^\w\-]+/g, '') // Remove todos os caracteres não-alfanuméricos
        .replace(/\-\-+/g, '-') // Substitui múltiplos - por um único -
        .replace(/^-+/, '') // Remove - do início
        .replace(/-+$/, '') // Remove - do final
    },
  },
}
</script>

<style lang="scss">
.cavalo-item {
  span {
    color: black;
  }

  a {
    &:hover {
      h3 {
        text-decoration: underline;
      }
    }
  }
}
</style>
