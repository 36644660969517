function useSEO ({ title, description, image, noindex }) {
  const defaultTitle = 'Horse Match'
  const defaultDescription =
    'O Horse Match chegou para você achar o match perfeito para o embrião dos sonhos! Aqui você vai poder escolher a combinação ideal entre doadoras e garanhões, avaliando uma ampla gama de critérios que vão além do aspecto físico, genético, histórico de premiações, entre outros detalhes. Reconhecendo pontos fortes e áreas de melhoria, a alquimia entre os animais contribui para a criação de equinos mais completos.'
  const defaultImage = '/shared.jpg'

  const setMetaTags = () => {
    const resolvedTitle = title ? `${title} | ${defaultTitle}` : defaultTitle
    const resolvedDescription = description || defaultDescription
    const resolvedImage = image || defaultImage

    document.title = resolvedTitle

    const setMeta = (name, content, attribute = 'name') => {
      let element = document.querySelector(`meta[${attribute}="${name}"]`)
      if (!element) {
        element = document.createElement('meta')
        element.setAttribute(attribute, name)
        document.getElementsByTagName('head')[0].appendChild(element)
      }
      element.setAttribute('content', content)
    }

    setMeta('description', resolvedDescription)
    setMeta('og:title', resolvedTitle, 'property')
    setMeta('og:description', resolvedDescription, 'property')
    setMeta('og:image', resolvedImage, 'property')
    setMeta('twitter:title', resolvedTitle)
    setMeta('twitter:description', resolvedDescription)
    setMeta('twitter:image', resolvedImage)
    setMeta('twitter:card', 'summary_large_image')

    if (noindex) {
      setMeta('robots', 'noindex, nofollow')
    }
  }

  return { setMetaTags }
}

export default {
  install (app) {
    app.config.globalProperties.$useSEO = useSEO
  }
}
