<template>
  <div class="grid-cavalos">
    <div class="row">
      <div v-for="(item, dataID) in data" :key="dataID" class="col-4">
        <grid-item :data="item" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>
/* Seu estilo aqui */
</style>
