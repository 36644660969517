<template>
  <header class="bg-blue-dark py-4">
    <div class="container">
      <div class="row">
        <div class="col-xxl-3 col-xl-6 col-lg-4 col-md-6 col-sm-8 col-8 offset-xxl-0 offset-xl-0 offset-lg-1 offset-md-3 offset-2 px-md-5 px-lg-0">
          <router-link :to="{ name: 'Home' }">
            <img
              src="/images/logo.webp"
              class="w-100"
              alt="Logomarca da Horse Math"
            >
          </router-link>
        </div>
        <div class="col-xxl-9 col-xl-6 col-lg-7 col-md-6 col-sm-12 col-12 offset-xxl-0 offset-xl-0 offset-lg-0 offset-sm-0 offset-0">
          <nav class="navbar navbar-expand-lg px-xxl-5 mt-xxl-3 mt-xl-3 mt-lg-4 mt-sm-4 mt-3 d-none d-lg-block">
            <div class="container-fluid d-md-flex mt-md-3 justify-content-center">
              <button
                class="navbar-toggler text-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon" />
              </button>
              <div
                id="navbarNav"
                class="collapse navbar-collapse d-flex justify-content-center"
              >
                <ul class="navbar-nav w-100 justify-content-between">
                  <li
                    v-for="(item, itemIndex) in menu"
                    :key="itemIndex"
                    class="nav-item"
                  >
                    <router-link
                      :to="item.route"
                      class="nav-link text-uppercase text-white text-center fw-bold"
                      :class="{ active: item.route.name == $route.name }"
                      aria-current="page"
                    >
                      {{ item.label }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: 'SectionHeader',
  data () {
    return {
      menu: [
        {
          label: 'Home',
          route: { name: 'Home' }
        },
        /**
                {
                    label: 'Busca de Animais',
                    route: { name: 'Home' }
                },
                **/
        {
          label: 'Cadastrar Animal',
          route: { name: 'CadastrarAnimal' }
        },
        {
          label: 'Cadastrar Técnico',
          route: { name: 'CadastrarTecnico' }
        },

        {
          label: 'Contato',
          route: { name: 'Contato' }
        }

      ]
    }
  }
}
</script>

<style lang="scss">
.nav-item a {
  &.active {
    border-bottom: 2px solid var(--bs-white);
  }

  /* Breakpoint lg */
  @media (min-width: 992px) {
    font-size: 15px;
  }

  /* Breakpoint xl */
  @media (min-width: 1200px) {
    font-size: 18px;
  }

  /* Breakpoint xxl */
  @media (min-width: 1400px) {
    font-size: 20px;
  }
}
</style>
