<template>
  <div class="pg-cadastrar-tecnico">
    <section-header />
    <div class="container">
      <div class="row">
        <div class="col offset-1 py-5">
          <div class="content content-col-1">
            <h1 class="text-yellow text-uppercase">Cadastro de Técnicos</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-5 col-10 offset-1">
          <form @submit.prevent="submitForm">
            <!-- Campo Nome-->
            <div class="row mb-3">
              <div class="col">
                <label for="campoNome" class="form-label">Nome Completo:</label>
                <input id="campoNome" v-model="campos.nome" type="text" class="form-control" required :disabled="loading" />
              </div>
            </div>

            <!-- Campo Data de Nascimento-->
            <div class="row mb-3">
              <div class="col-xxl-7">
                <label for="campoDataNascimento" class="form-label">Data de nascimento: (DD/MM/AAAA)</label>
                <input id="campoDataNascimento" v-model="campos.dataNascimento" type="date" class="form-control" required :disabled="loading" />
              </div>
            </div>

            <!-- Campo Cidade / Estado / CEP -->
            <div class="row mb-3">
              <div class="col-xxl-5">
                <label for="camposCriadorEnderecoCidade" class="form-label">Cidade:</label>
                <input id="camposCriadorEnderecoCidade" v-model="campos.enderecoCidade" type="text" class="form-control" required :disabled="loading" />
              </div>
              <div class="col-xxl-3">
                <label for="camposCriadorEnderecoEstado" class="form-label">Estado:</label>
                <select id="camposCriadorEnderecoEstado" v-model="campos.enderecoEstado" class="form-select" required :disabled="loading">
                  <option selected />
                  <option v-for="(estado, estadoID) in opcoes.estados" :key="estadoID" :value="estado">
                    {{ estado }}
                  </option>
                </select>
              </div>
              <div class="col-xxl-4">
                <label for="camposCriadorEnderecoCEP" class="form-label">CEP:</label>
                <input id="camposCriadorEnderecoCEP" v-model="campos.enderecoCEP" type="text" class="form-control" required :disabled="loading" v-mask="'##.###-###'" />
              </div>
            </div>

            <!-- Campo Telefone-->
            <div class="row mb-3">
              <div class="col-xxl-6">
                <label for="campoTelefone" class="form-label">Telefone de contato:</label>
                <input id="campoTelefone" v-model="campos.telefone" type="phone" class="form-control" required :disabled="loading" />
              </div>
            </div>

            <!-- Campo E-mail-->
            <div class="row mb-3">
              <div class="col">
                <label for="campoEmail" class="form-label">E-mail:</label>
                <input id="campoEmail" v-model="campos.email" type="email" class="form-control" required :disabled="loading" />
              </div>
            </div>

            <!-- Campo Formação Acadêmica -->
            <div class="row mb-3">
              <div class="col">
                <label for="campoFormacaoAcedemica" class="form-label">Formação acadêmica (curso/instituição/ano de formação):</label>
                <input id="campoFormacaoAcedemica" v-model="campos.formacaoAcademica" type="text" class="form-control" required :disabled="loading" />
              </div>
            </div>

            <!-- Campo Observacoes -->
            <div class="row mb-3">
              <div class="col">
                <label for="campoObservacoes" class="form-label">Observações:</label>
                <input id="campoObservacoes" v-model="campos.observacoes" type="text" class="form-control" required :disabled="loading" />
              </div>
            </div>

            <!-- Campo Documentos-->
            <div class="row my-5">
              <div class="col">
                <label for="campoDocumentos" class="form-label text-uppercase">Documentos:</label>
                <input id="campoDocumentos" ref="documentos" class="form-control" type="file" multiple required :disabled="loading" />
              </div>
            </div>

            <!-- BOTÃO -->
            <div class="row mb-5">
              <div class="col">
                <button class="btn btn-white px-5" type="submit" :disabled="loading">
                  {{ !loading ? 'Enviar formulário' : 'Aguarde...' }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-xl-4 col-10 offset-xl-1 offset-1">
          <div class="content content-col-2 pb-5 pt-3">
            <p>Cadastre-se para fazer parte da nossa equipe de técnicos qualificados.</p>
            <p>Você passará por uma avaliação para ser selecionado.</p>
            <h2 class="my-5 text-uppercase">faça parte da nossa equipe e vamos juntos contribuir para a criação de animais mais completos!</h2>

            <img src="/images/simbolo.webp" />
          </div>
        </div>
      </div>
    </div>
    <section-footer />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      campos: {
        dataNascimento: null,
        documentos: [],
        email: null,
        enderecoCEP: null,
        enderecoCidade: null,
        enderecoEstado: null,
        formacaoAcademica: null,
        nome: null,
        observacoes: null,
        telefone: null,
      },
      opcoes: {
        estados: [
          'AC', // Acre
          'AL', // Alagoas
          'AP', // Amapá
          'AM', // Amazonass
          'BA', // Bahia
          'CE', // Ceará
          'DF', // Distrito Federal
          'ES', // Espírito Santo
          'GO', // Goiás
          'MA', // Maranhão
          'MT', // Mato Grosso
          'MS', // Mato Grosso do Sul
          'MG', // Minas Gerais
          'PA', // Pará
          'PB', // Paraíba
          'PR', // Paraná
          'PE', // Pernambuco
          'PI', // Piauí
          'RJ', // Rio de Janeiro
          'RN', // Rio Grande do Norte
          'RS', // Rio Grande do Sul
          'RO', // Rondônia
          'RR', // Roraima
          'SC', // Santa Catarina
          'SP', // São Paulo
          'SE', // Sergipe
          'TO', // Tocantins
        ],
      },
    }
  },

  mounted() {
    this.$useSEO({
      title: 'Cadastrar Técnico',
      description: 'Faça o seu cadastro como técnico',
    }).setMetaTags()
  },

  methods: {
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },

    async validateFiles() {
      const files = this.$refs.documentos.files
      const maxAllowedSize = 2 * 1024 * 1024 // 2 MB em bytes
      for (const file of files) {
        if (file.size > maxAllowedSize) {
          await this.$swal.fire({
            title: 'Documentos inválidos',
            text: 'Um ou mais arquivos excedem o limite máximo de 2 MB.',
            icon: 'warning',
            confirmButtonText: 'Ok!',
          })

          this.$refs.documentos.value = '' // Limpa o campo de arquivo
          return false // Indica falha na validação
        }
      }
      return true // Indica sucesso na validação
    },

    async processFiles() {
      console.log('Validando documentos...')
      const isValid = await this.validateFiles()
      if (!isValid) {
        // Se a validação falhar, saia do método
        console.log('Validação falhou.')
        return false
      }
      console.log('Documentos validados!')

      const files = this.$refs.documentos.files // Acessa os arquivos após a validação
      try {
        const promises = Array.from(files).map((file) => this.fileToBase64(file))
        const base64Files = await Promise.all(promises)
        this.campos.documentos = base64Files // Armazena os documentos convertidos em Base64

        return true
      } catch (error) {
        console.error('Erro ao converter arquivos:', error)
      }
    },

    async submitForm() {
      console.log('Enviando dados...')

      this.loading = true

      const isValid = await this.processFiles()

      if (!isValid) {
        this.loading = false
        return
      }

      console.log('Documentos processados:', this.campos.documentos)

      try {
        const ret = await this.$sdk.post('/app/tecnicos', this.campos)

        console.log('[TÉCNICO]', ret)

        if (ret.status == 201) {
          await this.$swal.fire({
            title: 'Animal cadastrado com sucesso!',
            text: 'O dados foram enviados com sucesso. Em breve entraremos em contato com você.',
            icon: 'success',
            confirmButtonText: 'Ok!',
          })

          this.$router.push({ name: 'Home' })
        } else {
          await this.$swal.fire({
            title: 'Técnico cadastrado com sucesso!',
            text: 'Os seus dados foram enviados com sucesso. Em breve entraremos em contato com você.',
            icon: 'success',
            confirmButtonText: 'Ok!',
          })

          this.$router.push({ name: 'Home' })
        }
      } catch (error) {
        console.error('Failed to fetch users:', error.message)

        this.$swal.fire({
          title: 'Houve um erro ao cadastraro técnico',
          text: 'Infelizmente tivemos um problema técnico ao enviar os seus dados. Tente novamente em breve.',
          icon: 'error',
          confirmButtonText: 'Ok!',
        })
      }

      this.loading = false
    },
  },
}
</script>

<style lang="scss"></style>
