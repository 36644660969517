import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '@pages/Home.vue'
import CadastrarAnimalPage from '@pages/CadastrarAnimal.vue'
import CadastrarTecnicoPage from '@pages/CadastrarTecnico.vue'
import Contato from '@pages/Contato.vue'
import Busca from '@pages/Busca.vue'
import Animal from '@pages/Animal.vue'
import Match from '@pages/Match.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/cadastrar-animal',
    name: 'CadastrarAnimal',
    component: CadastrarAnimalPage,
  },
  {
    path: '/cadastrar-tecnico',
    name: 'CadastrarTecnico',
    component: CadastrarTecnicoPage,
  },
  {
    path: '/contato',
    name: 'Contato',
    component: Contato,
  },
  {
    path: '/buscar',
    name: 'Buscar',
    component: Busca,
  },
  {
    path: '/animal/:id/:slug',
    name: 'Animal',
    component: Animal,
  },
  {
    path: '/match/:id/:slug',
    name: 'Match',
    component: Match,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // sempre rola para o topo
    return { top: 0 }
  },
})

export default router
