<template>
  <div class="pg-contato">
    <section-header />
    <div class="container">
      <div class="row">
        <div class="col offset-1 py-5">
          <div class="content content-col-1">
            <h1 class="text-yellow text-uppercase">
              Contato
            </h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-5 col-10 offset-1">
          <form @submit.prevent="submitForm">
            <!-- Campo Nome-->
            <div class="row mb-3">
              <div class="col">
                <label
                  for="campoNome"
                  class="form-label"
                >Nome Completo:</label>
                <input
                  id="campoNome"
                  v-model="campos.nome"
                  type="text"
                  class="form-control"
                  required
                  :disabled="loading"
                >
              </div>
            </div>

            <!-- Campo Cidade / Estado-->
            <div class="row mb-3">
              <div class="col-xxl-5">
                <label
                  for="camposCidade"
                  class="form-label"
                >Cidade:</label>
                <input
                  id="camposCidade"
                  v-model="campos.cidade"
                  type="text"
                  class="form-control"
                  required
                  :disabled="loading"
                >
              </div>
              <div class="col-xxl-3">
                <label
                  for="camposEstado"
                  class="form-label"
                >Estado:</label>
                <select
                  id="camposEstado"
                  v-model="campos.estado"
                  class="form-select"
                  required
                  :disabled="loading"
                >
                  <option selected />
                  <option
                    v-for="(estado, estadoID) in opcoes.estados"
                    :key="estadoID"
                    :value="estado"
                  >
                    {{ estado }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Campo Telefone-->
            <div class="row mb-3">
              <div class="col-xxl-6">
                <label
                  for="campoTelefone"
                  class="form-label"
                >Telefone:</label>
                <input
                  id="campoTelefone"
                  v-model="campos.telefone"
                  type="phone"
                  class="form-control"
                  required
                  :disabled="loading"
                >
              </div>
            </div>

            <!-- Campo E-mail-->
            <div class="row mb-3">
              <div class="col">
                <label
                  for="campoEmail"
                  class="form-label"
                >E-mail:</label>
                <input
                  id="campoEmail"
                  v-model="campos.email"
                  type="email"
                  class="form-control"
                  required
                  :disabled="loading"
                >
              </div>
            </div>

            <!-- Campo Assunto -->
            <div class="row mb-3">
              <div class="col">
                <label
                  for="campoAssunto"
                  class="form-label"
                >Assunto:</label>
                <input
                  id="campoAssunto"
                  v-model="campos.assunto"
                  type="email"
                  class="form-control"
                  required
                  :disabled="loading"
                >
              </div>
            </div>

            <!-- Campo Mensagem -->
            <div class="row mb-3">
              <div class="col">
                <label
                  for="campoMensagem"
                  class="form-label"
                >Mensagem:</label>
                <textarea id="campoMensagem" v-model="campos.mensagem" class="form-control" rows="8" />
              </div>
            </div>

            <!-- BOTÃO -->
            <div class="row mb-5">
              <div class="col">
                <button
                  class="btn btn-white px-5"
                  type="submit"
                  :disabled="loading"
                >
                  {{ !loading ? 'Enviar formulário' : 'Aguarde...' }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-xl-4 col-10 offset-xl-1 offset-1">
          <div class="content content-col-2 pb-5">
            <p>
              Fale com a gente através dos contatos:<br>
              <strong>(41) 99640-4181</strong><br>
              <strong>horsematch@email.com.br</strong>
            </p>
            <p>
              <strong>Estamos localizados no Haras Vô Luiz:</strong><br>
              Endereço: Rua Miguel Izabel, 1000 - Bairro Nova Descoberta - Piçarras - SC.
            </p>

            <h2 class="my-5 text-uppercase">
              vamos juntos contribuir para a criação de animais mais completos!
            </h2>

            <img src="/images/simbolo.webp">
          </div>
        </div>
      </div>
    </div>
    <section-footer />
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      campos: {
        nome: null,
        cidade: null,
        estado: null,
        telefone: null,
        email: null,
        assunto: null,
        mensagem: null
      },
      opcoes: {
        estados: [
          'AC', // Acre
          'AL', // Alagoas
          'AP', // Amapá
          'AM', // Amazonass
          'BA', // Bahia
          'CE', // Ceará
          'DF', // Distrito Federal
          'ES', // Espírito Santo
          'GO', // Goiás
          'MA', // Maranhão
          'MT', // Mato Grosso
          'MS', // Mato Grosso do Sul
          'MG', // Minas Gerais
          'PA', // Pará
          'PB', // Paraíba
          'PR', // Paraná
          'PE', // Pernambuco
          'PI', // Piauí
          'RJ', // Rio de Janeiro
          'RN', // Rio Grande do Norte
          'RS', // Rio Grande do Sul
          'RO', // Rondônia
          'RR', // Roraima
          'SC', // Santa Catarina
          'SP', // São Paulo
          'SE', // Sergipe
          'TO' // Tocantins
        ]
      }
    }
  },

  mounted () {
    this.$useSEO({
      title: 'Contato',
      description: 'XXX'
    }).setMetaTags()
  },

  methods: {
    submitForm () {}
  }
}
</script>

<style lang="scss"></style>
