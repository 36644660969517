<template>
  <div class="loader">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center ">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.loader{
  background-color: var(--bs-white);
  padding: 100px 0;
}
</style>
