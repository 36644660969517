<template>
  <div class="pg-match">
    <section-header />

    <div class="bg-stripe-blue" v-if="!loading">
      <div class="container position-relative">
        <div class="row">
          <div class="col-xxl-4">
            <header>
              <span class="text-white text-uppercase d-block pb-2">Combinações Indicadas para:</span>
              <h1 class="fs-1 text-uppercase">{{ animal.animalNome }}</h1>
            </header>

            <p class="text-black my-5 pe-5">
              Nossos técnicos indicam o cruzamento com os animais selecionados ao lado, mas caso queira fazer uma combinação baseada em características específicas,
              <strong>preencha o questionário abaixo e receba um resultado prsonalizado:</strong>
            </p>
          </div>
          <div class="col-xxl-7 offset-xxl-1 mb-5">
            <div class="row">
              <div class="col">
                <cavalo-item :data="animal.indicacao1Reg" v-if="animal.indicacao1Reg" />
              </div>
              <div class="col">
                <cavalo-item :data="animal.indicacao2Reg" v-if="animal.indicacao2Reg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary" v-if="!loading">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1 class="fw-1 text-center my-3 text=uppercase">Descubra o Match Perfeito:</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white pb-5" v-if="!loading">
      <div class="container">
        <div class="row border-bottom border-dark mb-5">
          <div v-for="i in 4" :key="i" class="col">
            <span class="caracteristica caracteristica-titulo d-block text-center text-uppercase py-5 user-select-none" :class="verifyCaracteristicas(i) ? 'text-primary fw-bold' : 'text-black-50'">
              {{ i < 4 ? `Característica ${i}` : `Resultado` }}
            </span>
          </div>
        </div>

        <!-- ETAPA 1- ESCOLHER CARACTERISTICAS-->
        <div v-if="!loading && !mostrarResultado" class="row">
          <div class="col">
            <h2 class="text-primary text-center fs-3">Quais características são as mais importantes pra você?</h2>
            <p class="text-center text-black">Selecione 3 características que você busca em um animal:</p>
          </div>
          <div class="container">
            <div class="row">
              <div v-for="(caracteristica, caracteristicaID) in caracteristicas" :key="caracteristicaID" class="col-lg-3 col-md-4 col-sm-6 col-12" @click="clickCaracteristica(caracteristica.ID)">
                <div
                  class="text-uppercase text-primary fw-bold text-center py-3 align-content-center border border-primary border-3 mb-3 rounded-3 cursor-pointer user-select-none"
                  :class="verifySelectedCaracteristica(caracteristica.ID) ? 'bg-primary text-white' : ''"
                >
                  {{ caracteristica.Nome }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ETAPA 2- RESULTADOS-->
        <div v-if="!loading && mostrarResultado" class="row">
          <div class="col">
            <h2 class="text-primary text-center fs-2 text-uppercase">Resultados</h2>
            <p class="text-center text-black">Com base nas suas escolhas, recomendamos um dos seguintes animais:</p>
          </div>
          <div class="container">
            <div class="row">
              <div v-for="resultado in resultados" :key="resultado.ID" class="col">
                <cavalo-item :data="resultado" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary py-1" v-if="!loading">
      <div class="container">
        <div class="row">
          <div class="col-3 offset-2 position-relative">
            <img src="/images/logo-circulo.webp" class="position-absolute end-0" :style="{ top: '-24px' }" />
          </div>
          <div class="col">
            <span
              v-if="!mostrarResultado"
              class="text-uppercase fs-2 fw-bold d-block text-white text-decoration-none user-select-none"
              :class="liberaResultados ? 'cursor-pointer ' : 'opacity-25'"
              @click="clickResultado"
            >
              Ver resultado >
            </span>
            <span
              v-if="mostrarResultado"
              class="text-uppercase fs-2 fw-bold d-block text-white text-decoration-none user-select-none"
              :class="caracteristica1 && caracteristica2 && caracteristica3 ? 'cursor-pointer ' : 'opacity-25'"
              @click="clickTentarNovamente"
            >
              &lt; Tentar novamente
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white" v-if="!loading">
      <div class="container">
        <div class="row py-5">
          <div class="col-3 offset-3 d-flex justify-content-center">
            <img src="/images/mangalarga-logo.webp" />
          </div>

          <div class="col-3 d-flex justify-content-center">
            <img src="/images/associados.webp" />
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white" v-if="loading">
      <div class="container">
        <div class="row py-5">
          <loader />
        </div>
      </div>
    </div>

    <section-footer />
  </div>
</template>

<script>
export default {
  data() {
    return {
      animal: null,
      loading: true,
      caracteristica1: null,
      caracteristica2: null,
      caracteristica3: null,
      mostrarResultado: false,
      caracteristicas: [],
      resultados: null,
      liberaResultados: false,
    }
  },

  async mounted() {
    this.loading = true
    let animalID = this.$route.params.id
    let animal = await this.$sdk.get(`/app/animais/${animalID}`)
    this.animal = animal.data
    console.log('[ANIMAL]', animal)

    // carrega opções de filtro
    let opcoes = await this.$sdk.get('/app/opcoes')

    if (opcoes.data.caracteristicas) {
      this.caracteristicas = opcoes.data.caracteristicas
    }

    this.$useSEO({
      title: `Match com ${animal.data.animalNome}`,
      description: `Match com ${animal.data.animalNome}`,
    }).setMetaTags()

    this.loading = false
  },

  methods: {
    getCavalo(i) {
      return this[`cavalo${i}`]
    },

    verifyCaracteristicas(c) {
      return this[`caracteristica${c}`]
    },

    async verifySelectedCaracteristica(c) {
      if (this.caracteristica1 === c) {
        return true
      } else if (this.caracteristica2 === c) {
        return true
      } else if (this.caracteristica3 === c) {
        return true
      } else {
        return false
      }
    },

    async clickCaracteristica(c) {
      this.liberaResultados = false

      if (this.caracteristica1 === c) {
        this.caracteristica1 = null
        return
      } else if (this.caracteristica2 === c) {
        this.caracteristica2 = null
        return
      } else if (this.caracteristica3 === c) {
        this.caracteristica3 = null
        return
      }

      // verifica se já está selecionado e desseleciona
      if (!this.caracteristica1) {
        this.caracteristica1 = c
      } else if (!this.caracteristica2) {
        this.caracteristica2 = c
      } else if (!this.caracteristica3) {
        this.caracteristica3 = c
      } else {
        await this.$swal.fire({
          title: 'Limite de Características Atingido',
          text: 'Você já escolheu as 3 características para encontrar o par ideal para seu pet! Se quiser alterar alguma das opções selecionadas, é só clicar novamente na característica desejada e escolher uma nova. Assim, você ajusta suas preferências até achar o match perfeito!',
          icon: 'warning',
          confirmButtonText: 'Ok!',
        })
      }
      if (this.caracteristica1 && this.caracteristica2 && this.caracteristica3) {
        let resultados = await this.$sdk.get('/app/match', {
          particulars: `${this.caracteristica1},${this.caracteristica2},${this.caracteristica3}`,
          id: this.$route.params.id,
        })

        if (resultados.status === 200) {
          this.resultados = resultados.data

          console.log('[RESULTADOS]', this.resultados)
          this.liberaResultados = true
        } else {
          await this.$swal.fire({
            title: 'O Match está indisponível agora',
            text: 'Tente novamente daqui a pouco.',
            icon: 'warning',
            confirmButtonText: 'Ok!',
          })
        }
      }
    },

    async clickResultado() {
      if (this.resultados) {
        this.mostrarResultado = true
      }
    },
    async clickTentarNovamente() {
      this.caracteristica1 = null
      this.caracteristica2 = null
      this.caracteristica3 = null
      this.mostrarResultado = false
      this.liberaResultados = false
    },
  },
}
</script>
