<template>
  <div class="pg-home">
    <section-header />

    <section>
      <div class="container py-5">
        <div class="row">
          <div class="col-xxl-5 col-lg-4 col-md-4 col-8 offset-xxl-0 offset-2">
            <img src="/images/cavalos.webp" class="w-100" />
          </div>
          <div
            class="col-xxl-5 col-xl-6 col-lg-8 col-md-8 col-10 offset-xxl-0 offset-md-2 offset-1"
          >
            <div class="content">
              <h1 class="text-uppercase">CONHEÇA O HORSE MATCH</h1>

              <p>
                O Horse Match é uma parceria entre o Haras Vô Luiz e a Lenzi
                Central de Reprodução Equina, e chegou para você achar o match
                perfeito para o embrião dos sonhos!
              </p>
              <p>
                Aqui você vai poder escolher a combinação ideal entre doadoras e
                garanhões, avaliando uma ampla gama de critérios que vão além do
                aspecto físico, genético, histórico de premiações, entre outros
                detalhes. Reconhecendo os pontos fortes, a combinação de
                características específicas dos animais irá ajudar no
                melhoramento genético da raça escolhida.
              </p>
              <p>
                Você também pode cadastrar seus animais para que façam parte do
                catálogo do Horse Match, ampliando a probabilidade de acerto nos
                cruzamentos e de sucesso no seu criatório.
              </p>

              <h2 class="text-uppercase">Vamos em busca do Match perfeito!</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-xxl-6 col-xl-8 col-lg-8 col-md-12 col-10 offset-xxl-5 offset-xl-4 offset-lg-4 offset-md-0x offset-1 mt-4"
          >
            <router-link class="btn btn-white me-3" :to="{ name: 'Buscar' }">
              Quero conhecer os animais
            </router-link>
            <router-link
              class="btn btn-white me-3"
              :to="{ name: 'CadastrarAnimal' }"
            >
              Quero cadastrar um animal
            </router-link>

            <a href="#">
              <img
                src="/images/banner-mangalarda-marchador.webp"
                class="my-3"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
    <section-footer />
  </div>
</template>

<script>
export default {
  name: "HomePage",
  mounted() {
    this.$useSEO({
      title: "Home",
    }).setMetaTags();
  },
};
</script>

<style scoped>
h1 {
  color: blue;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}
</style>
